.Primary {
    background-color: var(--primary-color);
    color: var(--background-pop);
    font-size: large;
    border-radius: 8px;
    cursor: pointer;
    height: 50px;
    border: none;
}

.Secondary {
    background-color: inherit;
    border-color: var(--primary-color);
    color: var(--primary-color);
    border-radius: 8px;
    font-size: large;
    cursor: pointer;
    height: 50px;
}

.Create {
    position: absolute;
    right: 10px;
    bottom: 10px;

    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: none;
    background-color: var(--primary-color);
    color: var(--background-pop);
    font-size: 40px;
    text-decoration: none;
    padding: 10px;

    transition-duration: .1s;
    cursor: pointer;
}

.Create:focus {
    border: none;
}

.Create:active {
    background-color: inherit;
    border-style: outset;
    border-color: var(--primary-color);
    color: var(--primary-color);
    border-radius: 50%;
}

.Picture {
    color: var(--primary-color);
    height: 40px;
    width: 40px;
    cursor: pointer;
}

.Active {
    transform: rotate(45deg);
}