.Wrap {
    display: flex;
    flex-wrap: wrap;
    border-radius: 10px;
    overflow: hidden;
    justify-content: space-between;
    gap: 4px;
    max-height: 600px;
}


.Image {
    width: 100%;
    max-height: 600px;
    cursor: pointer;
    object-fit: cover;
    max-height: 300px;
}

.Image:only-child {
    max-height: 600px;
}

.Spinner {
    width: 20px;
    height: 20px;
    position: relative;
    top: 50%;
    left: 50%;
}