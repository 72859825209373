.Card {
    height: 300px;
}

.Form {
    display: flex;
    flex-direction: column;
    gap: 5px
}

.Button {
    background-color: #7b39ed;
    color: white;
    font-size: large;
    border-radius: 8px;
    cursor: pointer;
    height: 50px;
    border: none;
}

.Error {
    color: var(--error);
    margin-bottom: 20px;
}