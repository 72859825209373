.Header {
    width: 100%;
    height: 5rem;
    justify-content: right;
    display: flex;
    flex-wrap: wrap-reverse;
}

.Header>* {
    margin: auto 20px auto 0;
}

.NavigationButton {
    font-size: 23px;
    font: Heebo;
    font-weight: 500;
    color: var(--text-color);
    margin: auto 20px auto 0;
    cursor: pointer;
}

.NavigationButton:hover {
    color: var(--background-pop);
    background-color: var(--primary-color);
}

.HamburgerMenu {
    display: none;
    margin: auto 20px auto 0;
}

.HamburgerMenu svg {
    display: unset;
    cursor: pointer;
    width: 30px;
    height: 30px;
    color: var(--primary-color);
}

@media screen and (max-width: 480px) {
    .HideWhenSmall {
        display: none;
    }

    .HamburgerMenu {
        display: unset;
    }
}

a {
    text-decoration: none;
}

.Primary {
    color: var(--primary-color);
}

.Primary:hover {
    background-color: var(--background-pop);
}