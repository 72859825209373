.Backdrop {
    z-index: 1;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: fixed;
    background-color: rgba(0, 0, 0, 0.4);
}


.ChangeForm {
    max-width: 600px;
    padding: 20px;
    background-color: var(--background-pop);
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 10px;
    z-index: 1;
}


.ProfilePicture {
    width: 200px;
    height: 200px;
    border-radius: 50%;
    cursor: pointer;
    border: 2px var(--primary-color) solid;
}

.AboveProfilePicture {
    width: 200px;
    height: 200px;
    border-radius: 50%;
    color: rgba(128, 128, 128, 1);
    position: relative;
    object-fit: cover;
}

.ProfileChangeCameraButton {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.CancelButton {
    position: absolute;
    top: 10px;
    right: 10px;
    border-radius: 50%;
    width: 50px;
    height: 50px;
}