.Card {
    background-color: var(--background-pop);
    border-radius: 10px;
    min-height: 110px;
    margin: 20px auto 20px auto;
    padding: 20px;
    gap: 20px;
    display: flex;
    animation: floatIn .5s cubic-bezier(0.175, 0.885, 0.32, 1.275) both;
}

.ProfilePicture {
    width: 70px;
    height: 70px;
    border-radius: 50%;
}

.Form {
    width: 100%;
    height: 100%;
    max-height: 700px;
}

.Button {
    width: 100px;
    margin-left: auto;
    float: right;
}

.Picture {
    float: right;
    margin: 5px 10px;
}

.CreateChirpInput {
    font-size: 20px;
    width: 100%;
    height: 100%;
    padding: 0;
    margin-bottom: 20px;
    margin-top: 20px;

    background-color: var(--background-pop);
    color: var(--primary-muted-color);

    text-overflow: ellipsis;

    border: none;
}

.Spinner {
    width: 50px;
    height: 50px;
    float: right;
}

@keyframes floatIn {
    0% {
        transform: translateY(-50px);
        opacity: 0%;
    }

    100% {
        transform: translate(0px);
    }
}

@-moz-keyframes floatIn {
    0% {
        transform: translateY(-50px);
    }

    100% {
        transform: translate(0px);
    }
}