.Spinner {
    color: var(--primary-color);

    animation-name: rotate;
    animation-duration: 2s;
    animation-timing-function: cubic-bezier(0.075, 0.82, 0.250, 1);
    animation-iteration-count: infinite;
}

@keyframes rotate {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

@-moz-keyframes rotate {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}