.Card {
    background-color: var(--background-pop);
    border-radius: 10px;
    min-height: 100px;
    margin: 20px 0;
    display: flex;
    animation: floatIn .5s cubic-bezier(0.175, 0.885, 0.32, 1.275) both;
    padding: 20px;
    gap: 20px;
    z-index: 1;
}

.Username {
    font-size: 23px;
    font-weight: 500;
    color: var(--text-color-muted);
    float: left;
}

.Handle {
    color: var(--primary-color);
    width: fit-content;
    height: fit-content;
    margin: auto 0;
}

.ProfilePicture {
    width: 70px;
    height: 70px;
    border-radius: 50%;
}

.Substance {
    gap: 10px;
    word-break: break-word;
    height: 100%;
    width: 100%;
    display: grid;
    position: relative;
}

.Text {
    font-size: 20px;
    color: var(--text-color);
}

.UserInfoBar {
    display: flex;
    justify-content: space-between;
    min-height: 24px;
}

.UserLink {
    display: flex;
}

@media (prefers-reduced-motion: no-preference) {
    @keyframes floatIn {
        0% {
            transform: translateX(50px);
            opacity: 0%;
        }

        100% {
            transform: translate(0px);
        }
    }

    @-moz-keyframes floatIn {
        0% {
            transform: translateX(50px);
        }

        100% {
            transform: translate(0px);
        }
    }
}

.ExtraDots {
    height: auto;
    width: 24px;
    color: var(--primary-color);
    cursor: pointer;
}

@media (hover) {
    .ExtraDots {
        display: none;
    }

    .Card:hover .ExtraDots {
        display: inherit;
    }

}

.Backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
}


.Popup {
    position: absolute;
    width: fit-content;
    height: fit-content;
    background-color: var(--background-pop);
    border-radius: 5px;
    border: var(--background-color) 4px solid;
    right: 0;
    top: 24px;
    padding: 10px;
    z-index: 100;
    cursor: pointer;
}

.Popup>* {
    cursor: pointer;
}

.DeleteButton {
    border: none;
    color: var(--error);
    width: 150px;
}

.DeleteButton:hover {
    background-color: var(--background-pop);
}

.CancelButton {
    background-color: var(--background-pop);
    color: var(--text-color);
    padding: 5px;
    width: 150px;

}

.Image {
    max-height: 90vh;
    max-width: 100%;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
}

.ComeFromLeft {
    animation: comeFromLeft .3s linear 0s 1 forwards;
}

.ComeFromRight {
    animation: comeFromRight .3s linear 0s 1 forwards;
}


.ImageBackDrop1 {
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    position: fixed;
    z-index: 1;
    background-color: var(--background-color);
    overflow: hidden;
}

.ImageBackDrop2 {
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    position: fixed;
    z-index: 1;
    filter: blur(100px);
}

.ExitButton {
    position: fixed;
    top: 10px;
    right: 10px;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    z-index: 1;
    background-color: var(--background-color);
}

.NavigateLeft {
    position: fixed;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 2;
    background-color: var(--background-color);
    height: 100px;
    width: 50px;
}

.NavigateRight {
    position: fixed;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
    background-color: var(--background-color);
    height: 100px;
    width: 50px;
}

@keyframes comeFromLeft {
    0% {
        left: 0;
    }

    100% {
        left: 50%;
    }
}

@keyframes comeFromRight {
    0% {
        right: 0;
    }

    100% {
        left: 50%;
    }
}