.Label {
    color: var(--text-color-muted);
}

.TextInput {
    border: none;
    border: 1px solid var(--primary-muted-color);
    background-color: var(--background-color);
    border-radius: 8px;
    height: 40px;
    margin-bottom: 10px;
    padding: 0 20px;
    font-size: 25px;
    color: var(--primary-muted-color);
}