.Card {
    height: 300px;
}

.Form {
    display: flex;
    flex-direction: column;
    gap: 5px
}

.Password {
    font-size: 10px;
}


.Error {
    color: var(--error);
    margin-bottom: 20px;
}