.Spinner {
    width: 50%;
    height: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    animation-name: rotate;
}

@keyframes rotate {
    0% {
        transform: translate(-50%, -50%) rotate(0deg);
    }

    100% {
        transform: translate(-50%, -50%) rotate(360deg);
    }
}

@-moz-keyframes rotate {
    0% {
        transform: translate(-50%, -50%) rotate(0deg);
    }

    100% {
        transform: translate(-50%, -50%) rotate(360deg);
    }
}

.ImageContainer {
    width: 100%;
    position: relative;
    height: 300px;
}

.ImageNotLoadedYetGray {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    background-color: rgba(128, 128, 128, 0.7);
    transform: translate(-50%, -50%);
}

.Image {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.CancelButton {
    position: absolute;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    top: 10px;
    right: 10px;
    border-color: var(--text-color);
    color: var(--text-color);
}